import { getModalsRootElement } from 'constants/modals'
import { formatImgUrl } from 'constants/configs/theme.config'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useEffect, useRef, useState } from 'react'

import * as yup from 'yup'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon
} from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { Input } from 'ui/Input'
import { useTimer } from 'features/common/hooks/useTimer'
import classNames from "classnames"

import { NOTIFICATION_TYPES, showNotification } from "../../../common/notifications"
import APP_CONFIG from "../../../../constants/configs/app.config"

import {
  RESERVE_PHONE_MODAL_STEPS,
  reservePhoneActions,
  sendReservePhoneCodeThunk,
  setReservePhoneThunk
} from './reservePhoneSlice'
import {
  getReservePhoneModalCurrentStepSelector,
  getReservePhoneModalPhoneSelector,
  getReservePhoneModalStatusSelector,
  getReservePhoneModalTempPhoneSelector
} from './selectors'

const PHONE_PREFIX = APP_CONFIG.user_phone_mask ? APP_CONFIG.user_phone_mask.slice(0,2).trim() : '+7'
const PHONE_MASK = APP_CONFIG.user_phone_mask ? APP_CONFIG.user_phone_mask.slice(PHONE_PREFIX.length).trim() : '999 999 99 99'
const MIN_PHONE_LENGTH = APP_CONFIG.user_phone_mask?.length - PHONE_PREFIX.length - 1
const MAX_PHONE_LENGTH = 10

const abortController = new AbortController()

export const ReservePhoneModal = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const opened = getReservePhoneModalStatusSelector()
  const currentStep = getReservePhoneModalCurrentStepSelector()
  const tempPhone = getReservePhoneModalTempPhoneSelector()
  const reservePhone = getReservePhoneModalPhoneSelector()

  const inputRef = useRef<any>(null)

  const [phoneError, setPhoneError] = useState('')


  const { startTimer, formattedData, isTimerActive } = useTimer(60000)

  const askForCode = (phone, setErrors) => {
    dispatch(setReservePhoneThunk({
      phone: phone.replace(/\s/g, ''),

      errorCallback: (error) => {
        if (error.code === 'TooManyAsksOfOtp') {
          dispatch(reservePhoneActions.setCurrentStep(RESERVE_PHONE_MODAL_STEPS.tooManyRequests))

          return
        }

        if (setErrors && error?.detail?.phone && error?.detail?.phone.startsWith('Given value')) {
          setErrors({ phone: t('l_common_incorrectPhoneNumberError') })
          setPhoneError(t('l_common_incorrectPhoneNumberError'))
          return
        }

        if (setErrors && error?.detail?.phone && error?.detail?.phone.startsWith('The reserve phone')) {
          setErrors({ phone: t('l_notification_backupNumberNotSame') })
          setPhoneError(t('l_notification_backupNumberNotSame'))

          return
        }

        if (error?.detail?.sms_otp === 'Required') {
          dispatch(reservePhoneActions.setCurrentStep(RESERVE_PHONE_MODAL_STEPS.code))
          dispatch(reservePhoneActions.setTempPhone(phone))
          startTimer()

          return
        }
      },

      successCallback: () => {
        dispatch(reservePhoneActions.setCurrentStep(RESERVE_PHONE_MODAL_STEPS.code))
        dispatch(reservePhoneActions.setTempPhone(phone))
      },
      signal: abortController.signal
    }))
  }

  const handleResendCode = () => {
    askForCode(tempPhone, null)
  }

  const handleClose = () => {
    dispatch(reservePhoneActions.closeModal())
  }

  const handleCloseAndReset = (handleReset) => () => {
    handleClose()
    handleReset()
    setPhoneError('')
    abortController.abort()
  }

  const handleSubmit = (values: any, { setErrors, resetForm }) => {
    if (currentStep === RESERVE_PHONE_MODAL_STEPS.phone) {
      setPhoneError('')
      if (reservePhone === `${PHONE_PREFIX} ${values.phone}`) {
        setErrors({
          phone: t('l_notification_sameBackupNumber')
        })
        setPhoneError(t('l_notification_sameBackupNumber'))
        return
      }

      askForCode(`${PHONE_PREFIX} ${values.phone}`, setErrors)
    }

    if (currentStep === RESERVE_PHONE_MODAL_STEPS.code) {
      dispatch(sendReservePhoneCodeThunk({
        phone: tempPhone.replace(/\s/g, ''),
        code: values.code,

        errorCallback: (error) => {
          if (error.code === 'TooManyAsksOfOtp') {
            dispatch(reservePhoneActions.setCurrentStep(RESERVE_PHONE_MODAL_STEPS.tooManyRequests))

            return
          }

          if (error?.detail?.sms_otp) {
            setErrors({ code: t('l_common_codeNotValidError') })

            return
          }
        },

        successCallback: () => {
          dispatch(reservePhoneActions.setPhone(tempPhone))
          dispatch(reservePhoneActions.closeModal())
          showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: t('l_notification_backupNumberChanged')
          })
        },
        signal: abortController.signal
      }))
    }

    if (currentStep === RESERVE_PHONE_MODAL_STEPS.tooManyRequests) {
      resetForm()
      handleClose()
    }
  }

  const validation = {
    phone: yup.object().shape({
      phone: yup.string().required(t('l_common_incorrectPhoneNumberError')).min(13, t('l_common_incorrectPhoneNumberError'))
    }),
    code: yup.object().shape({
      code: yup.string().required(t('l_common_codeNotValidError')).min(6, t('l_common_codeNotValidError'))
    })
  }

  const okBtnTexts = {
    [RESERVE_PHONE_MODAL_STEPS.phone]: t('l_common_next'),
    [RESERVE_PHONE_MODAL_STEPS.code]: t('l_common_next'),
    [RESERVE_PHONE_MODAL_STEPS.tooManyRequests]: t('a_common_ok'),
  }

  useEffect(() => {
    setPhoneError('')
  }, [])

  useEffect(() => {
    if (!opened) {
      return
    }

    inputRef.current?.focus()

    if (currentStep === RESERVE_PHONE_MODAL_STEPS.phone && reservePhone) {
      inputRef.current?.setSelectionRange(0, 15)
    }
  }, [currentStep, opened, reservePhone])

  const handleInputRef = (ref: HTMLInputElement) => {
    inputRef.current = ref
  }

  function padStringWithSpaces(inputString, desiredLength) {
    if (inputString.length >= desiredLength) {
      return inputString
    }

    const stringToAppend = ' '.repeat(desiredLength - inputString.length)
    return inputString + stringToAppend
  }

  const handleBeforeOnChange = (newState: any, oldState: any, userInput: string) => {
    let normalizedValue = userInput && userInput.replace(/\s/g, '')

    if (normalizedValue && normalizedValue.length > MAX_PHONE_LENGTH) {
      normalizedValue = normalizedValue.slice(normalizedValue.length - MAX_PHONE_LENGTH)
      return {
        ...newState,
        value: padStringWithSpaces(normalizedValue, MIN_PHONE_LENGTH)
      }
    }
    return newState
  }

  return (
    <Formik
      initialValues={{ phone: reservePhone ? reservePhone.substring(3) : '', code: '' }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validation[currentStep]}
      validateOnChange
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        touched
      }) => (
        <Form>
          <ModalDialog
            header={currentStep !== RESERVE_PHONE_MODAL_STEPS.tooManyRequests && (
              <ModalHeader
                title={t('l_settings_addBackupNumberTitle')}
              />
            )}
            footer={(
              <ModalFooter
                buttonList={[
                  currentStep !== RESERVE_PHONE_MODAL_STEPS.tooManyRequests && (
                    <SecondaryButton
                      key={'buttonCancel'}
                      actionName={t('a_common_cancel')}
                      onAction={handleClose}
                    />
                  ),
                  <StyledPrimaryButton
                    key={'buttonAction'}
                    actionName={okBtnTexts[currentStep]}
                    onAction={handleSubmit}
                  />
                ]}
              />
            )}
            parentBlock={getModalsRootElement()}
            isOpened={opened}
            onCloseModal={handleCloseAndReset(handleReset)}
          >
            <ContentBox>
              {
                currentStep === RESERVE_PHONE_MODAL_STEPS.code && (
                  <>
                    <Description>
                      {t('l_auth_verificationCode', { number: tempPhone })}
                    </Description>

                    <InputLabel>
                      {t('l_common_enterSMScode')}
                    </InputLabel>

                    <InputBox>
                      <StyledInput
                        mask="999999"
                        placeholder={t('l_common_SMScode')}
                        value={values.code}
                        onChange={handleChange}
                        name="code"
                        maskChar={null}
                        inputRef={handleInputRef}
                      />

                      {!!errors.code && <WarningIcon iconName="warning" />}
                    </InputBox>

                    {!!errors.code && (
                      <ErrorBox>
                        {errors.code}
                      </ErrorBox>
                    )}

                    {isTimerActive && (
                      <ResendCodeIn>
                        {t('l_common_codeResendTimerOther', { number: formattedData.seconds })}
                      </ResendCodeIn>
                    )}

                    {
                      !isTimerActive && (
                        <ResendCodeBtn onClick={handleResendCode}>
                          {t('l_common_resendCode')}
                        </ResendCodeBtn>
                      )
                    }

                    <EmptyDivider />
                  </>
                )
              }

              {
                currentStep === RESERVE_PHONE_MODAL_STEPS.phone && (
                  <>
                    <InputLabel>
                      {t('l_settings_enterBackupNumber')}
                    </InputLabel>

                    <InputBox>
                      <PhonePrefix
                        value={PHONE_PREFIX}
                        disabled
                      />

                      <StyledInput
                        beforeMaskedValueChange={handleBeforeOnChange}
                        className={classNames({ error: !!phoneError, phone: true })}
                        mask={PHONE_MASK}
                        placeholder={t('l_common_phone')}
                        value={values.phone}
                        onChange={(e) => {
                          setPhoneError('')
                          handleChange(e)
                        }}
                        name="phone"
                        maskChar={null}
                        inputRef={handleInputRef}
                      />

                      {!!phoneError && touched.phone && <WarningIcon iconName="warning" />}
                    </InputBox>

                    {!!phoneError && touched.phone &&
                      (
                        <ErrorBox>
                          {errors.phone}
                        </ErrorBox>
                      )
                    }
                  </>
                )
              }

              {
                currentStep === RESERVE_PHONE_MODAL_STEPS.tooManyRequests && (
                  <TooManyBox>
                    <TooManyImg
                      src={formatImgUrl("/assets/placeholders-images/internet-connection.svg")}
                    />

                    <TooManyTitle>
                      {t('l_common_tooManyAttemptsError')}
                    </TooManyTitle>
                  </TooManyBox>
                )}
            </ContentBox>
          </ModalDialog>
        </Form>
      )}
    </Formik>
  )
}

const ContentBox = styled.div`
  width: calc(100vw - 80px);
  max-width: 560px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 560px;
  }
`

const InputLabel = styled.label`
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: var(--text-primary);
`

const ErrorBox = styled.p`
  margin-top: 8px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--text-secondary);
`

const InputBox = styled.div`
  margin-top: 5px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

const WarningIcon = styled(SpriteIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: #DADADA;
`

const StyledInput = styled(Input)`
  &.phone {
    padding-left: 30px;
  }
`

const PhonePrefix = styled.input`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--text-primary);
  background: transparent;
  position: absolute;
  left: 8px;
  height: 100%;
  border: none;
  outline: none;
  height: 38px;
  width: 20px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 8px;
`

const Description = styled.p`
  height: 49px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
`

const ResendCodeIn = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
`

const EmptyDivider = styled.div`
  height: 40px;
`

const ResendCodeBtn = styled.button`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--text-primary);
  text-align: left;
`

const TooManyBox = styled.div`
  padding: 44px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TooManyImg = styled.img`
  width: 292px;
  height: 162px;
`

const TooManyTitle = styled.p`
  max-width: 226px;
  margin-top: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
`
