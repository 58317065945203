import APP_CONFIG from "../constants/configs/app.config"

export const getUserLogin = (userData) => {
  const type = APP_CONFIG.user_login_type
  const logins = userData?.logins

  if (!logins || !logins.length) {
    return ''
  }

  if (type === 'email') {
    const emailLogin = logins.find(login => login.startsWith('email:'))

    if (!emailLogin) {
      return ''
    }

    return emailLogin.split('email:')[1]
  }

  if (type === 'phoneNumber') {
    const prefix = APP_CONFIG.user_phone_prefix

    const phoneLogin = logins.find(login => login.includes(prefix))

    if (!phoneLogin) {
      return ''
    }

    return `${prefix}${phoneLogin.split(prefix)[1]}`
  }

  return ''
}
