import { useCallback, useEffect } from "react"

import React, { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { LoadingOverlay } from 'ui/LoadingOverlay'
import { useAppDispatch } from  "store"

import { getUserSettingsSelector } from "../user/selectors"
import { LOADING_STATUSES } from "../../constants/loadingStatuses"
import { getOwnerData } from "../../pages/Documents/Persons"
import { analytics, ANALYTICS_EVENTS } from "../common/analytics"
import { appActions } from "../../store/app"
import APP_CONFIG from "../../constants/configs/app.config"
import { TypeModal } from "../../pages/Documents"
import { getModalsRootElement } from "../../constants/modals"
import { AlbumsSeasonGrid } from "../albums/albums-season/AlbumsSeasonsGrid"

import { getDocumentItemsActionStatusSelector, getDocumentSelectedTypesSelector, getDocumentsWalletTypesSelector,getShowCreateModalSelector,getTypeModalSelector } from './selectors'
import { DocumentTypeCard } from './DocumentTypeCard'
import { DOCUMENT_ITEMS_ACTION_STATUSES } from './types'
import { PersonsLine } from "./persons/PersonsLine"
import { ADD_PERSON_MODAL_TYPE, getDocumentTypePersonThunk, getPersonsThunk, personsActions } from "./persons/personsSlice"
import { getCurrentPersonIdSelector, getLoadingPersonsStatusSelector, getPersonsSelector } from "./persons/selectors"
import { EmptyDocumentTypesPlaceholder } from "./EmptyDocumentTypesPlaceholder"
import { documentsActions, createDocumentTypeThunk, renameDocumentTypeThunk } from "./documentsSlice"
import { CreateDocumentModal } from "./CreateDocumentModal"

export const getTextLoadingOverlay = (status: DOCUMENT_ITEMS_ACTION_STATUSES, t) => {
  switch(status) {
  case DOCUMENT_ITEMS_ACTION_STATUSES.UPLOADING:
    return t('l_notification_uploading')
  case DOCUMENT_ITEMS_ACTION_STATUSES.DOWNLOADING:
    return t('l_common_downloading')
  case DOCUMENT_ITEMS_ACTION_STATUSES.RENAMING:
    return t('l_notification_renaming')
  case DOCUMENT_ITEMS_ACTION_STATUSES.REMOVING:
    return t('l_notification_deleting')
  case DOCUMENT_ITEMS_ACTION_STATUSES.CREATING:
    return t('l_notification_creating')
  default:
    return t('l_common_loading')
  }
}

export const DocumentsTypes = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const status = getDocumentItemsActionStatusSelector()
  const documentsTypes = getDocumentsWalletTypesSelector()
  const userSettings = getUserSettingsSelector()
  const persons = getPersonsSelector()
  const loadingPersonsStatus = getLoadingPersonsStatusSelector()
  const isShowCreateDocumentModal = getShowCreateModalSelector()
  const typeModal = getTypeModalSelector()
  const selectedTypes = getDocumentSelectedTypesSelector()

  const isPersonsVisible = !!APP_CONFIG.is_persons_visible
  const currentPersonId = getCurrentPersonIdSelector()

  const handleCardClick = (id) => {
    const link = currentPersonId !== 'owner' ? `/documents/person/${currentPersonId}/${id}` : `/documents/${id}`

    navigate(link)
  }

  const handleClickItem = (personId) => {
    dispatch(personsActions.changeSelectPerson(personId))
  }

  const handleClickEdit = () => {
    navigate(`/documents/persons`)
  }

  const handleClickAddPerson = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_PERSON_CREATE_CLICK)

    dispatch(personsActions.setTypeModal(ADD_PERSON_MODAL_TYPE.ADD_PERSON))
    dispatch(personsActions.togglePersonalSettingModalOpened(true))
  }

  const getNameSubtitle = () => {
    if (persons.length === 0) return
    const selectedItem = persons.find(item => item.isSelect === true)
    return selectedItem.name === t('l_dw_me') ? t('l_dw_myDocuments') : selectedItem.name
  }

  useEffect(() => {
    dispatch(appActions.toggleLayoutOverflowEnabling(false))

    if (persons.length === 0) {
      dispatch(getPersonsThunk({ ...getOwnerData(t) }))
    }
  }, [])

  useEffect(() => {
    dispatch(getDocumentTypePersonThunk({ currentPersonId }))
  }, [persons])

  const handleCreateDocument = () => {
    dispatch(documentsActions.setTypeModal(TypeModal.CREATE))
    dispatch(documentsActions.setShowCreateModal(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleItemSelection = useCallback((id, isSelected) => {
    dispatch(documentsActions.updateItemType({ id, selected: isSelected }))
  }, [])

  const handleCloseCreateDocumentModal = () => {
    dispatch(documentsActions.setShowCreateModal(false))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleSubmitRenameDocumentModal = (name: string) => {
    dispatch(renameDocumentTypeThunk({ id: selectedTypes[0].id, name }))
    dispatch(documentsActions.setShowCreateModal(false))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleSubmitCreateDocumentModal = (name: string) => {
    dispatch(createDocumentTypeThunk({ name , callback: handleCardClick }))
    dispatch(documentsActions.setShowCreateModal(false))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const propsCreateModal = (type: string) => {
    switch (type) {
    case TypeModal.CREATE:
      return {
        onSubmitModal: handleSubmitCreateDocumentModal,
        renamingName: '',
        texts: {
          TITLE: t('a_dw_createDocsFolder'),
          PLACEHOLDER: t('l_files_createFolderPlaceholder'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_create'),
          SUB_TITLE: t('l_common_name'),
        }
      }
    case TypeModal.RENAME:
      return {
        onSubmitModal: handleSubmitRenameDocumentModal,
        renamingName: selectedTypes.length > 0 && selectedTypes[0].name,
        texts: {
          TITLE: t('a_common_rename'),
          PLACEHOLDER: '',
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          SUB_TITLE: t('l_common_name'),
        }
      }
    default:
      null
    }
  }

  return (
    <DocumentsBox className={className}>
      {isPersonsVisible && (
        <PersonsLine
          items={persons}
          textTitle={t('l_dw_people')}
          textEdit={t('a_common_edit')}
          textAdd={t('a_common_add')}
          onClickItem={handleClickItem}
          onClickEdit={handleClickEdit}
          onClickAddPerson={handleClickAddPerson}
          maxPersons={!!userSettings['user_document_wallet'] && userSettings['user_document_wallet'].persons_limit}
          isLoading={loadingPersonsStatus === LOADING_STATUSES.LOADING}
        />
      )}

      {isPersonsVisible && loadingPersonsStatus === LOADING_STATUSES.SUCCEEDED && (
        <TitleWithNameBox>
          {currentPersonId !== 'owner' ? t('l_dw_profileDocs', { name: getNameSubtitle() }) : getNameSubtitle()}
        </TitleWithNameBox>
      )}

      { documentsTypes.length === 0 ?   (
        <EmptyDocumentTypesPlaceholder
          texts={{
            title: t('l_dw_noDocsFolders'),
            description: t('l_dw_noDocsFoldersMessage'),
            createDocument: t('a_dw_createDocsFolder')
          }}
          onCreateDocument={handleCreateDocument}
        />
      ) : (
        <AlbumsSeasonGrid>
          {
            documentsTypes.map(documentType => (
              <DocumentTypeCard
                key={documentType.id}
                documentTypeName={documentType.name}
                description={`${documentType.documents_count} ${t('l_files_itemsUploadWidget', { number: documentType.documents_count })}`}
                imgUrl={documentType.previewUrl}
                type={documentType.preview_id}
                onClick={() => handleCardClick(documentType.id)}
                documentTypePhotoCount={t('l_common_countElements', { number: documentType.documents_count })}
                onSelect={handleItemSelection}
                isSelected={documentType.selected}
                id={documentType.id}
              />
            ))
          }
        </AlbumsSeasonGrid>
      )}

      <CreateDocumentModal
        isShown={isShowCreateDocumentModal}
        parentBlock={getModalsRootElement()}
        onCloseModal={handleCloseCreateDocumentModal}
        {...propsCreateModal(typeModal)}
      />

      <LoadingOverlay text={getTextLoadingOverlay(status, t)}
        visible={status !== DOCUMENT_ITEMS_ACTION_STATUSES.IDLE}
      />

    </DocumentsBox>
  )
}

const DocumentsBox = styled.div`
  height: calc(100% - 66px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  width: 100%;
  padding-bottom: 40px;
  
  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;
    
    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const TitleWithNameBox = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
`
